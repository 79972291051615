const CALLER = {
  CONTROLLER: 'Controller',
  TRACKING: 'Tracking',
  FETCH_SERVICES: 'FetchServices',
  FETCH_PRECONDITIONAL_SERVICES: 'FetchPreconditionalServices',
  ROUTER_CONDITIONS: 'RouterConditions',
};

const ERROR_METRICS = {
  LANDING_GROUP_CONFIG_FAILS: {
    sendToNewrelic: true,
    message: 'Landing Group config middleware fails',
    description: 'Default error in landing group config middleware',
    metricName: 'landing.landingGroupConfig.error',
    logName: 'LANDING.LANDING_GROUP_CONFIG.ERROR',
  },
  FETCH_PRECONDITIONAL_SERVICES_FAILS: {
    sendToNewrelic: true,
    message: 'Fetch preconditional services middlware fails',
    description: 'Default error in fetch preconditional services middleware',
    metricName: 'landing.fetch_preconditional_services.error',
    logName: 'LANDING.FETCH_PRECONDITIONAL_SERVICES.ERROR',
  },
  EXTRA_PRECONDITIONAL_SERVICES: {
    sendToNewrelic: false,
    sendToDD: false,
    message: 'Extra preconditional services',
    description: 'Only one service can be configured at the moment',
    metricName: 'landing.extra_preconditional_services.error',
    logName: 'LANDING.FETCH_PRECONDITIONAL_SERVICES.EXTRA',
  },
  PRECONDITIONAL_SERVICE_REJECTED: {
    sendToNewrelic: false,
    message: 'Preconditional service rejected',
    description: 'At least one Preconditional service threw an error',
    metricName: 'landing.preconditional_service.rejected',
    logName: 'LANDING.ROUTER_CONDITIONS.REJECTED',
  },
  PRECONDITIONAL_SERVICE_PARSER_ERROR: {
    sendToNewrelic: false,
    sendToDD: false,
    message: 'Parser error in preconditional service',
    description: 'Error resolving some placeholder from pre_services',
    metricName: 'landing.preconditional_service_parser.error',
    logName: 'LANDING.FETCH_PRECONDITIONAL_SERVICES.PARSER_ERROR',
  },
  ROUTER_CONDITIONS_FAILS: {
    sendToNewrelic: true,
    message: 'Router conditions middleware fail',
    description: 'Default error in router conditions middleware',
    metricName: 'landing.router_conditions.error',
    logName: 'LANDING.ROUTER_CONDITIONS.ERROR',
  },
  SET_PRECONDITIONAL_CONFIGS_FAIL: {
    sendToNewrelic: true,
    sendToDD: false,
    message: 'Set preconditional configs middleware fail',
    description: 'Default error in set preconditional configs middleware',
    metricName: 'landing.set_preconditional_configs.error',
    logName: 'LANDING.SET_PRECONDITIONAL_CONFIGS.ERROR',
  },
  BACKUP_FILE_NOT_FOUND_IN_STORAGE: {
    sendToNewrelic: false,
    message: 'Backup file not found',
    description: 'File not found in object storage',
    metricName: 'landing.showPageBackup.error',
    logName: 'LANDING.SHOW_PAGE_BACKUP.NOT_FOUND',
  },
  FAIL_SHOW_LANDING_BACKUP: {
    sendToNewrelic: false,
    message: 'Show landing backup fails',
    description: 'Default error in show landing backup',
    metricName: 'landing.showPageBackup.error',
    logName: 'LANDING.SHOW_PAGE_BACKUP.ERROR',
  },
  LANDING_CONFIG_FAILS: {
    sendToNewrelic: true,
    message: 'Fetch landing config middleware fails',
    description: 'Default error in fetch landing config middleware',
    metricName: 'landing.fetch_config.error',
    logName: 'LANDING.FETCH_CONFIG.ERROR',
  },
  LANDING_CONFIG_INCOMPLETE_ATTRIBUTES: {
    sendToNewrelic: false,
    message: 'Missing data in landing config',
    description: 'Landing settings: head and tracking, are required',
    metricName: 'landing.fetch_config.incomplete',
    logName: 'LANDING.FETCH_CONFIG.INCOMPLETE',
  },
  LANDING_CONFIG_PREVIEW_BAD_REQUEST: {
    sendToNewrelic: false,
    message: 'Missing data to fecth preview landing config',
    description:
      'Query params: version_id, variant_name and landing_group, are required',
    metricName: 'landing.fetch_preview_config.incomplete',
    logName: 'LANDING.FETCH_PREVIEW_CONFIG.INCOMPLETE',
  },
  ACTIVE_PRODUCTS_INACTIVE_FOR_CHANNEL: {
    sendToNewrelic: false,
    message: 'Product is inactive for channel',
    description: 'The fetched product is inactive',
    metricName: 'landing.fetch_active_products.error',
    logName: 'LANDING.FETCH_PRODUCTS.INACTIVE',
  },
  FETCH_PRODUCTS_FAILS: {
    sendToNewrelic: true,
    message: 'Fetch active products middleware fails',
    description: 'Default error in fetch active products middleware',
    metricName: 'landing.fetch_products.error',
    logName: 'LANDING.FETCH_PRODUCTS.ERROR',
  },
  NAVIGATION_BAD_REQUEST: {
    sendToNewrelic: true,
    sendToDD: false,
    message: 'Missing data to get navigation bar',
    description:
      'Params: multiproductId, channelProducts and coupon, are required',
    metricName: 'navigation_bar.fetch_data.incomplete',
    logName: 'NAVIGATION_BAR.FETCH_DATA.INCOMPLETE',
  },
  NAVIGATION_MALFORMED_ID: {
    sendToNewrelic: false,
    sendToDD: false,
    message: 'Malformed id to get navigation bar data',
    description: 'id cannot be decrypted',
    metricName: 'navigation_bar.malformed.id',
    logName: 'NAVIGATION_BAR.MALFORMED.ID',
  },
  NAVIGATION_FAILS: {
    sendToNewrelic: true,
    sendToDD: false,
    message: 'Navigation middleware fails',
    description: 'Default error in navigation middleware',
    metricName: 'navigation_bar.fetch_data.error',
    logName: 'NAVIGATION_BAR.FETCH_DATA.ERROR',
  },
  CHANNEL_BAD_REQUEST: {
    sendToNewrelic: false,
    message: 'Missing data to fecth channel',
    description: 'Param in config: bu, is required',
    metricName: 'landing.fetch_channel.invalid_bu',
    logName: 'LANDING.FETCH_CHANNEL.INVALID_BU',
  },
  FETCH_CHANNEL_FAILS: {
    sendToNewrelic: false,
    message: 'Fetch channel middleware fails',
    description: 'Default error in fetch channel middleware',
    metricName: 'landing.fetch_channel.error',
    logName: 'LANDING.FETCH_CHANNEL.ERROR',
  },
  TRACKING_FAILS: {
    sendToNewrelic: true,
    message: 'Tracking middleware fails',
    description: 'Default error in tracking middleware',
    metricName: 'landing.set_tracking.error',
    logName: 'LANDING.SET_TRACKING.ERROR',
  },
  INVALID_FORMAT_URL: {
    sendToNewrelic: false,
    message: 'Url invalid',
    description: 'The url contains invalid characters',
    metricName: 'landing.filter_urls.invalid',
    logName: 'LANDING.FILTER_URLS.INVALID',
  },
  FILTER_URL_FAILS: {
    sendToNewrelic: true,
    message: 'Filter url middleware fails',
    description: 'Default error in filter url middleware',
    metricName: 'landing.filter_urls.error',
    logName: 'LANDING.FILTER_URLS.ERROR',
  },
  NO_SECTIONS: {
    sendToNewrelic: false,
    message: 'Landing without sections',
    description: 'The landing doesn`t have valid sections',
    metricName: 'landing.render.sections_empty',
    logName: 'LANDING.RENDER.SECTIONS_EMPTY',
  },
  CONTROLLER_FAILS: {
    sendToNewrelic: true,
    message: 'Controller landing fails',
    description: 'Default error in controller',
    metricName: 'landing.render.error',
    logName: 'LANDING.RENDER.ERROR',
  },
  SERVICE_MANDATORY_REJECTED: {
    sendToNewrelic: false,
    message: 'Service mandatory rejected',
    description: 'At least one mandatory service threw an error',
    metricName: 'landing.fetch_services.error',
    logName: 'LANDING.FETCH_SERVICES.MANDATORY_REJECTED',
  },
  FETCH_SERVICES_FAILS: {
    sendToNewrelic: true,
    message: 'Fetch services middlware fails',
    description: 'Default error in fetch services middleware',
    metricName: 'landing.fetch_services.error',
    logName: 'LANDING.FETCH_SERVICES.ERROR',
  },
  SET_COUPON_FAILS: {
    sendToNewrelic: true,
    message: 'Set coupon middleware fails',
    description: 'Default error in set coupon middleware',
    metricName: 'landing.set_coupon.error',
    logName: 'LANDING.SET_COUPON.ERROR',
  },
  INVALID_COUPON: {
    sendToNewrelic: false,
    sendToDD: false,
    message: 'Invalid coupon',
    description: 'The coupon must have more than 5 valid characters',
    metricName: 'landing.set_coupon.invalid',
    logName: 'LANDING.SET_COUPON.INVALID',
  },
  AVOID_REDIRECT_PREVIEW_FLOW: {
    sendToNewrelic: false,
    sendToDD: false,
    message: 'Avoid redirect in preview flow',
    description: 'Redirect is not necessary in preview flow',
    metricName: 'landing.fetch_group.avoidRedirect',
    logName: 'LANDING.LANDING_GROUP_CONFIG.AVOID_REDIRECT',
  },
  NOT_FOUND_LANDING_GROUP_CONFIG: {
    sendToNewrelic: false,
    message: 'Not found landing group config',
    description: 'The landing group config not found',
    metricName: 'landing.fetch_group.notFound',
    logName: 'LANDING.LANDING_GROUP_CONFIG.NOT_FOUND',
  },
};

const MELIDATA_MODE_SEND = 'send';
const MELIDATA_MODE_CLEAN_SEND = 'cleanAndSend';
const LOGO_MP =
  'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/Mercado-Pago-logo-azul--df3e1be2.png';

const SELLERS_SPACES = [
  {
    id: 1,
    name: 'point',
  },
  {
    id: 3,
    name: 'qr',
  },
  {
    id: 4,
    name: 'online-payments',
  },
  {
    id: 5,
    name: 'sellers-mercadopago',
  },
];

const DEPRECATED_SECTIONS = ['pricing'];

const GENERIC_COMPONENT_ALIAS_SECTIONS = [
  'paymentsteps',
  'moneyout',
  'prepaid',
  'kitqr',
  'credits',
  'security',
];

const SELLERS_SECTIONS = [
  'new-pricing',
  'sgs-accordion-slider',
  'sgs-animation-component',
  'sgs-card-plan',
  'sgs-device-list',
  'sgs-generic-hero',
  'sgs-hero',
  'sgs-images-highlight',
  'sgs-media-slider',
  'sgs-message-band',
  'sgs-multiproduct-selector',
  'sgs-payment-methods',
  'sgs-slider',
  'sgs-specs',
  'sgs-nav-bar',
  'sgs-fees-cards',
  'sgs-animated-image-cards',
  'sgs-taxes-money-now',
];

module.exports = {
  CALLER,
  DEPRECATED_SECTIONS,
  ERROR_METRICS,
  GENERIC_COMPONENT_ALIAS_SECTIONS,
  LOGO_MP,
  MELIDATA_MODE_CLEAN_SEND,
  MELIDATA_MODE_SEND,
  SELLERS_SPACES,
  SELLERS_SECTIONS,
};
